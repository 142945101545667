/**
 * @param {{
 *  organizationId: number;
 * }} props
 */
export function hasNetwork(organization) {
  if (!organization) return false;

  const hasNetworkId = !!organization?.network?.id;
  const hasNetworkUnleash =
    !!organization?.unleashStatus?.network_communication;

  return hasNetworkId && hasNetworkUnleash;
}
