import { isNetworkChild } from '../isNetworkChild';

describe('isNetworkChild', () => {
  let organization;

  beforeEach(() => {
    jest.clearAllMocks();

    organization = {
      id: 1,
      network: {
        id: 2,
      },
      unleashStatus: {
        network_communication: true,
      },
    };
  });

  it('should return false when organization is not provided', () => {
    expect(isNetworkChild()).toBe(false);
  });

  it('should return true when organization is child organization in network', () => {
    expect(isNetworkChild(organization)).toBe(true);
  });

  it('should return false when organization is a main organization in network', () => {
    organization.network.id = 1;
    expect(isNetworkChild(organization)).toBe(false);
  });

  it('should return false when organization has not netowrk unleash', () => {
    organization.unleashStatus.network_communication = false;
    expect(isNetworkChild(organization)).toBe(false);
  });
});